@media print {
  /* to remove unwanted url, paging */
  @page {
    size: auto; /* auto is the initial value */
    margin: 3mm 7mm 3mm 7mm; /* this affects the margin in the printer settings */
  }
  /* @page {
    size: portrait;
  } */

  /* to avoid the print of extra page */
  html,
  body {
    height: auto;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
